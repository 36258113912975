import * as React from "react";
import { resolveDisplayName } from "../utils";
import type { ComponentPropsWithoutRef} from "../Extend";
import { Extend } from "../Extend";
import { useCalendarContext } from "./CalendarContext";

type CalendarMonthElement = React.ElementRef<typeof Extend.table>;
export type CalendarMonthProps = ComponentPropsWithoutRef<typeof Extend.table> & {};

export const CalendarMonth = React.forwardRef<CalendarMonthElement, CalendarMonthProps>(({ ...props }, ref) => {
  const { labelID, captionID } = useCalendarContext("CalendarMonth");

  return <Extend.table {...props} role="grid" aria-labelledby={labelID} aria-describedby={captionID} ref={ref} />;
});

CalendarMonth.displayName = resolveDisplayName("Calendar", "Month");
