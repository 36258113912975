import React from "react";
import type { ComponentPropsWithoutRef} from "../Extend";
import { Extend } from "../Extend";
import { resolveDisplayName } from "../utils";
import { useCalendarContext } from "./CalendarContext";

type CalendarTitleElement = React.ElementRef<typeof Extend.div>;
interface CalendarTitleProps extends ComponentPropsWithoutRef<typeof Extend.div> {}

export const CalendarTitle = React.forwardRef<CalendarTitleElement, CalendarTitleProps>(function CalendarTitle(
  { ...props },
  ref
) {
  const { titleID } = useCalendarContext("CalendarTitle");
  return <Extend.div {...props} id={titleID} ref={ref} />;
});

CalendarTitle.displayName = resolveDisplayName("Calendar", "Title");
