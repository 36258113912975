import type dayjs from "dayjs";
import * as React from "react";
import type { ComponentPropsWithoutRef} from "../Extend";
import { Extend } from "../Extend";
import { resolveDisplayName } from "../utils";
import type { CalendarChildrenProps } from "./types";

type CalendarDayTextElement = React.ElementRef<typeof Extend.span>;
export type CalendarDayTextProps = Omit<ComponentPropsWithoutRef<typeof Extend.span>, "children"> & {
  day: dayjs.Dayjs;
  format?: string;
  children?: (props: CalendarChildrenProps) => React.ReactNode;
};

export const CalendarDayText = React.forwardRef<CalendarDayTextElement, CalendarDayTextProps>(
  ({ day, format = "D", children, ...props }, ref) => {
    const childrenMarkup = children ? children({ day }) : day.clone().format(format);
    return (
      <Extend.span {...props} aria-label={day.clone().format("YYYY/MM/DD")} ref={ref}>
        {childrenMarkup}
      </Extend.span>
    );
  }
);

CalendarDayText.displayName = resolveDisplayName("Calendar", "DayText");
