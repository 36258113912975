import * as React from "react";
import type { ComponentPropsWithoutRef} from "../Extend";
import { Extend } from "../Extend";
import { resolveDisplayName } from "../utils";
import { useCalendarContext } from "./CalendarContext";
import type { CalendarChildrenProps } from "./types";

type CalendarCaptionElement = React.ElementRef<typeof Extend.caption>;
export type CalendarCaptionProps = Omit<ComponentPropsWithoutRef<typeof Extend.caption>, "children"> & {
  children?: (props: CalendarChildrenProps) => React.ReactNode;
  label?: string;
  format?: string;
};
export const CalendarCaption = React.forwardRef<CalendarCaptionElement, CalendarCaptionProps>(
  ({ format = "YYYY/MM/DD", label, className, children, ...props }, ref) => {
    const { captionID, currentDay } = useCalendarContext("CalendarCaption");
    const childrenMarkup = children ? (
      children({ day: currentDay })
    ) : (
      <span className="visually-hidden">{`${label || "Today"}: ${currentDay.clone().format(format)}`}</span>
    );

    return (
      <Extend.caption {...props} id={captionID} ref={ref}>
        {childrenMarkup}
      </Extend.caption>
    );
  }
);

CalendarCaption.displayName = resolveDisplayName("Calendar", "Caption");
