import dayjs from "dayjs";
import React from "react";
import type { ComponentPropsWithoutRef} from "../Extend";
import { Extend } from "../Extend";
import { composeEventHandlers, createContext, resolveDisplayName } from "../utils";
import { useCalendarContext } from "./CalendarContext";

interface CalendarControlContextConfig {
  onPrevMonth: () => void;
  onNextMonth: () => void;
  onResetMonth: () => void;
}

const [CalendarControlProvider, useCalendarControlContext] =
  createContext<CalendarControlContextConfig>("CalendarControl");

export interface CalendarControlProps {
  children?: React.ReactNode;
  onPrevMonth?: (date: dayjs.Dayjs) => void;
  onNextMonth?: (date: dayjs.Dayjs) => void;
}

type ExtendButtonProps = ComponentPropsWithoutRef<typeof Extend.button>;
type CalendarControlButtonElement = React.ElementRef<typeof Extend.button>;
export interface CalendarControlButtonProps extends ExtendButtonProps {}

export const CalendarControlPrevButton = React.forwardRef<CalendarControlButtonElement, CalendarControlButtonProps>(
  function CalendarControlPrevButton({ "aria-label": ariaLbel, onClick, ...props }, ref) {
    const { onPrevMonth } = useCalendarControlContext("CalendarControlPrev");

    return (
      <Extend.button
        {...props}
        type="button"
        data-action="previous"
        aria-label={ariaLbel || "Previous month"}
        onClick={composeEventHandlers(onClick, onPrevMonth)}
        ref={ref}
      />
    );
  }
);

export const CalendarControlNextButton = React.forwardRef<CalendarControlButtonElement, CalendarControlButtonProps>(
  ({ "aria-label": ariaLabel, onClick, ...props }, ref) => {
    const { onNextMonth } = useCalendarControlContext("CalendarControlNext");

    return (
      <Extend.button
        {...props}
        type="button"
        data-action="next"
        aria-label={ariaLabel || "Next month"}
        onClick={composeEventHandlers(onClick, onNextMonth)}
        ref={ref}
      />
    );
  }
);

export const CalendarControlResetButton = React.forwardRef<CalendarControlButtonElement, CalendarControlButtonProps>(
  ({ "aria-label": ariaLabel, onClick, ...props }, ref) => {
    const { onResetMonth } = useCalendarControlContext("CalendarControlReset");

    return (
      <Extend.button
        {...props}
        data-action="reset"
        aria-label={ariaLabel || "Current month"}
        {...props}
        type="button"
        onClick={composeEventHandlers(onClick, onResetMonth)}
        ref={ref}
      />
    );
  }
);

export function CalendarControl({ children, onPrevMonth, onNextMonth }: CalendarControlProps) {
  const { currentDay, onCurrentMonthChange, onCurrentDayChange } = useCalendarContext("CalendarControl");

  const handlePrevMonth = React.useCallback(() => {
    const day = currentDay.clone();
    const prev = day.subtract(1, "month");
    onCurrentMonthChange(prev);
    onCurrentDayChange(prev);
    if (onPrevMonth) {
      onPrevMonth(prev);
    }
  }, [currentDay, onCurrentMonthChange, onCurrentDayChange, onPrevMonth]);

  const handleNextMonth = React.useCallback(() => {
    const day = currentDay.clone();
    const next = day.add(1, "month");
    onCurrentMonthChange(next);
    onCurrentDayChange(next);
    if (onNextMonth) {
      onNextMonth(next);
    }
  }, [currentDay, onCurrentMonthChange, onCurrentDayChange, onNextMonth]);

  const handleResetMonth = React.useCallback(() => {
    const now = dayjs(new Date());
    const day = currentDay.clone();
    const reset = day.set("month", now.get("month"));
    onCurrentMonthChange(reset);
    onCurrentDayChange(reset);
  }, [onCurrentMonthChange, onCurrentDayChange, currentDay]);

  return (
    <CalendarControlProvider
      onPrevMonth={handlePrevMonth}
      onNextMonth={handleNextMonth}
      onResetMonth={handleResetMonth}
    >
      {children}
    </CalendarControlProvider>
  );
}

CalendarControl.displayName = resolveDisplayName("Calendar", "Control");
CalendarControlPrevButton.displayName = resolveDisplayName("Calendar", "Prev");
CalendarControlNextButton.displayName = resolveDisplayName("Calendar", "Next");
CalendarControlResetButton.displayName = resolveDisplayName("Calendar", "Reset");
