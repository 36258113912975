import type dayjs from "dayjs";
import * as React from "react";
import type { ComponentPropsWithoutRef} from "../Extend";
import { Extend } from "../Extend";
import { resolveDisplayName } from "../utils";
import { useCalendarContext } from "./CalendarContext";
import type { CalendarChildrenProps } from "./types";

type CalendarDayActionElement = React.ElementRef<typeof Extend.span>;
export type CalendarDayActionProps = Omit<ComponentPropsWithoutRef<typeof Extend.span>, "children"> & {
  disabled?: boolean;
  day: dayjs.Dayjs;
  onDayClick?: (day: dayjs.Dayjs) => void;
  children?: (props: CalendarChildrenProps) => React.ReactNode;
};

export const CalendarDayAction = React.forwardRef<CalendarDayActionElement, CalendarDayActionProps>(
  (
    { "aria-label": ariaLabel, disabled: disabledProp, day, onDayClick, onClick, onKeyDown, children, ...props },
    ref
  ) => {
    const { onDayChange, currentDay } = useCalendarContext("CalendarDayAction");

    const disabled = React.useMemo(() => {
      return disabledProp || day.get("month") !== currentDay.get("month");
    }, [disabledProp, currentDay, day]);

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLSpanElement>) => {
        if (disabled) {
          return;
        }
        onDayChange?.(day);
        onDayClick?.(day);
        onClick?.(event);
      },
      [disabled, day, onDayChange, onDayClick, onClick]
    );

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLSpanElement>) => {
        if (disabled) {
          return;
        }
        if (event.key === "Enter") {
          onDayChange?.(day);
          onDayClick?.(day);
          onKeyDown?.(event);
        }
      },
      [disabled, day, onDayChange, onDayClick, onKeyDown]
    );

    const childrenMarkup = day && children ? children({ day }) : null;

    return (
      <Extend.span
        {...props}
        data-state={disabled ? "disabled" : undefined}
        role="button"
        tabIndex={disabled ? -1 : 0}
        aria-disabled={disabled}
        aria-label={ariaLabel || day.format("YYYY/MM/DD")}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        ref={ref}
      >
        {childrenMarkup}
      </Extend.span>
    );
  }
);

CalendarDayAction.displayName = resolveDisplayName("Calendar", "DayAction");
