import React from "react";
import type { ComponentPropsWithoutRef, Extend } from "../Extend";
import { resolveDisplayName } from "../utils";
import { useCalendarContext } from "./CalendarContext";
import type { CalendarChildrenProps } from "./types";

type CalendarLabelElement = React.ElementRef<typeof Extend.label>;
export interface CalendarLabelProps extends Omit<ComponentPropsWithoutRef<typeof Extend.label>, "children"> {
  format?: string;
  children?: (props: CalendarChildrenProps) => React.ReactNode;
}

export const CalendarLabel = React.forwardRef<CalendarLabelElement, CalendarLabelProps>(function CalendarLabel(
  { format = "YYYY/MM", children, ...props },
  ref
) {
  const { labelID, currentDay } = useCalendarContext("CalendarLabel");
  const childrenMarkup = children ? children({ day: currentDay }) : currentDay.format(format);

  return (
    <label {...props} id={labelID} ref={ref}>
      {childrenMarkup}
    </label>
  );
});

CalendarLabel.displayName = resolveDisplayName("Calendar", "Label");
