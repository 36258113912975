import * as React from "react";
import type dayjs from "dayjs";
import type { ComponentPropsWithoutRef} from "../Extend";
import { Extend } from "../Extend";
import { resolveDisplayName } from "../utils";
import { useCalendarContext } from "./CalendarContext";
import { CalendarDay } from "./CalendarDay";

type ChildrenProps = {
  day: dayjs.Dayjs | null;
};

type CalendarWeekElement = React.ElementRef<typeof Extend.tr>;
export type CalendarWeekProps = Omit<ComponentPropsWithoutRef<typeof Extend.tr>, "children"> & {
  week: (dayjs.Dayjs | null)[];
  children?: (props: ChildrenProps) => React.ReactNode;
};

export const CalendarWeek = React.forwardRef<CalendarWeekElement, CalendarWeekProps>(
  ({ week, children, ...props }, ref) => {
    const { calendarID } = useCalendarContext("CalendarWeek");
    const key = (index: number) => `${calendarID}-day[${index}]`;
    const childrenMarkup = children
      ? week.map((day, index) => {
          return (
            <React.Fragment key={key(index)}>
              {children({
                day,
              })}
            </React.Fragment>
          );
        })
      : week.map((day, index) => <CalendarDay key={key(index)} day={day} />);

    return (
      <Extend.tr {...props} ref={ref}>
        {childrenMarkup}
      </Extend.tr>
    );
  }
);

CalendarWeek.displayName = resolveDisplayName("Calendar", "Week");
