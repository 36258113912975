import dayjs from "dayjs";
import * as React from "react";
import type { ComponentPropsWithoutRef} from "../Extend";
import { Extend } from "../Extend";
import { resolveDisplayName } from "../utils";
import { useCalendarContext } from "./CalendarContext";
import { CalendarDayText } from "./CalendarDayText";
import type { CalendarChildrenProps, CalendarData } from "./types";

type ChildrenProps = CalendarChildrenProps & {
  data: CalendarData[];
};

type CalendarDayElement = React.ElementRef<typeof Extend.td>;
export type CalendarDayProps = Omit<ComponentPropsWithoutRef<typeof Extend.td>, "children"> & {
  day: dayjs.Dayjs | null;
  format?: string;
  children?: (props: ChildrenProps) => React.ReactNode;
};

export const CalendarDay = React.forwardRef<CalendarDayElement, CalendarDayProps>(
  ({ day, format = "DD/MM/YYYY", children, ...props }, ref) => {
    const { data } = useCalendarContext("CalendarDay");
    const calendarData = React.useMemo(() => {
      if (!day) {
        return [];
      }
      return data.filter((d) => {
        const dataDay = dayjs(d.day).format("YYYY-MM-DD");
        const current = day.format("YYYY-MM-DD");
        return dataDay === current;
      });
    }, [data, day]);

    const childrenMarkup =
      day && children ? children({ day, data: calendarData }) : day ? <CalendarDayText day={day} /> : null;
    return (
      <Extend.td {...props} role="gridcell" title={day?.format(format)} data-day={day?.get("day")} ref={ref}>
        {childrenMarkup}
      </Extend.td>
    );
  }
);

CalendarDay.displayName = resolveDisplayName("Calendar", "Day");
